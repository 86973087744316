import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';

import Routing from './utils/Routing';
import Pages from './pages';
import PageWrapper from './components/PageWrapper';
import {authContext} from './authContext';

function App(_props) {
  const { authorised, initiateLogin, isPending } = useContext(authContext);
  const location = useLocation();
  const navigate = useNavigate();
  const allowAccessRoutes = !isPending && authorised;
  const onlyErrorRoute = !isPending && !authorised;

  useEffect(() => {
    if (!authorised) {
      initiateLogin(location?.pathname, location?.search);
    }
  }, [authorised, initiateLogin, location]);

  return (
    <PageWrapper>
      {allowAccessRoutes &&
        <Routes>
          <Route path={Routing.path()} element={<Pages.Home/>}/>
          <Route path={Routing.path(Routing.BOARDING1)}
                 element={<Pages.OnBoarding1/>}/>
          <Route path={Routing.path(Routing.BOARDING2)}
                 element={<Pages.OnBoarding2/>}/>
          <Route path={Routing.path(Routing.APP_LANDING)}
                 element={<Pages.AppLanding/>}/>
          <Route path={Routing.path(Routing.CAMERA)}
                 element={<Pages.Camera location={location} navigate={navigate}/>}/>
          <Route path={Routing.path(Routing.UPLOAD_IMAGE)}
                 element={<Pages.UploadImages location={location}/>}/>
          <Route path={Routing.path(Routing.ERROR)}
                 element={<Pages.Error location={location}/>}/>
          <Route path="*" element={<Pages.PageNotFound/>}/>
        </Routes>
      }
      {onlyErrorRoute &&
        <>
          <Routes>
            <Route
              path={Routing.path()}
              element={<Pages.Error location={location}/>}/>
            <Route
              path={Routing.path(Routing.ERROR)}
              element={<Pages.Error location={location}/>}/>
          </Routes>
          {location.pathname !== "/error" && <Navigate to="/error"/>}
        </>
      }
    </PageWrapper>
  );
}

export default App;
