import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';

import NavigationButton from '../components/NavigationButton';
import StartButton from '../components/StartButton';
import appLandingImage from "../assets/appLandingPage.svg";
import { ORIENTATION_NO_ACCESS } from '../constants/PermissionsError';
import { REDIRECT_PATH } from '../constants/SessionStorageVariables';
import Routing from '../utils/Routing';

function AppLanding(_props) {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem(REDIRECT_PATH, Routing.APP_LANDING);
  }, [])

  const requestMotionAccess = () => {
    // ios 13+
    if (window.DeviceMotionEvent !== undefined && typeof window.DeviceMotionEvent.requestPermission === 'function') {
      window.DeviceMotionEvent.requestPermission().then(permissionState => {
         if (permissionState === "granted") {
           return navigate(Routing.path(Routing.CAMERA), { state: { acceptedOnboarding: true } });
        } else {
           return navigate(Routing.path(Routing.ERROR), { state: { errorType: ORIENTATION_NO_ACCESS } });
         }
      }).catch(error => {
        return navigate(Routing.path(Routing.ERROR), { state: { errorType: ORIENTATION_NO_ACCESS } });
      });
    } else {
      return navigate(Routing.path(Routing.CAMERA), { state: { acceptedOnboarding: true } });
    }
  }

  return (
    <>
      <Typography variant='h2'>Let's begin taking photos!</Typography>
      <Typography variant='body1' component='ol'>
        <li>Stand <strong>1.5 meters</strong> away from your desk</li>
        <li>Hold your phone in <strong>landscape</strong> mode</li>
        <li>Place <strong>your computer in the center</strong> of the image and take the first photo</li>
        <li>Click <strong>Allow permissions</strong>.You'll need to give the app access to your phone camera to assess your exam environment.</li>
      </Typography>
      <img
        src={appLandingImage}
        style={{display: 'block', margin: '0px auto', width: 'auto', height: '100%', maxHeight: '30vh'}}
        alt="A person stands 1.5 meters in front of their desk, holding a phone in landscape mode with camera facing ahead at 90 degrees"
      />
      <div style={{maxHeight: "13vh", display: 'flex', flexDirection: 'column'}}>
        <StartButton handleClick={requestMotionAccess} label="Allow permissions" />
        <NavigationButton styleType="FILL_CENTERED" variant='text' to={Routing.path()} label="View onboarding again" />
      </div>
    </>
  );
}

export default AppLanding;
