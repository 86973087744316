import Compress from "compress.js";

export default async function compressImage(image) {
    const compress = new Compress();
    const file = await compress.compress([image], {
        maxWidth: 1920,
        maxHeight: 1920,
    }).then((result => {
        const img = result[0];
        const base64str = img.data;
        const imgMime = img.ext;
        const blob = Compress.convertBase64ToFile(base64str, imgMime);
        return new File([blob], img.alt, {type: "image/jpg"});
    }));

    return file;
}